/* Top Nav_______________________________________________ */
.full-topbar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-family: var( --main-font);
}
.full-topbar > a > img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    padding: 5px 0px 0px 20px;
}
.TopNav{
    display: flex;
    justify-content: right;
    margin: 30px 50px 0px 0px;
}
.top-nav-item {
    text-decoration: none;
    padding: 10px;
    color: var(--light);
    font-size: .9em;
}
.TopNav :hover{ 
    color: var(--pop);
}

/* Art Nav_______________________________________________ */

.art-nav-container{
   position: fixed !important;
   height: 100vh;
   width: 100vw;
   background:  linear-gradient(0deg, rgba(84, 53, 6, 0.6), rgba(191, 157, 132, 0.504)), url('../../Media/Covers/different-brushstrokes-of-golden-paint-drawn-on-gr-2023-11-27-05-13-58-utc.jpeg');
   transition: background-color ease .5s;
   color: var(--light);
   top: 0;
   z-index: -100;
}
 
.art-nav{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30vh 0vh 30vh 0vh;
}

.art-nav-item{
    text-decoration: none;
    font-family: var(--third-font);
    font-size: 3em;
    color: var(--light);
    margin: 0px 2%;
}

.art-nav-item:hover {
    border-bottom: var(--light) solid 1.5px;
    transition: .2s;
}
.work-container{
    margin-bottom: 100vh;
    background-color: var(--dark);
}

.wall-hover{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)), url('../../Media/Covers/walls-cover.jpeg');
    transition: 2s ease-in;
}

.illustration-hover{
    background: url('../../Media/Covers/Dark-Texture.jpeg');
    transition: 2s ease-in;
}

.canvas-hover{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../Media/Covers/canvas-cover.jpeg');
     transition: 2s ease-in;
}
    