:root{
  --main-font: 'Nanum Myeongjo', serif; 
  --second-font: 'Fira Sans', sans-serif;
  --third-font: 'Chakra Petch', sans-serif;
  --light: white;
  --dark: #211a1a;
  --pop: rgb(229, 110, 26);
}
html{
  background-color: var(--dark);
  color: var(--light);
}
.App {
  text-align: center;
}
/* Error 404 page __________________________________________________ */

.error-pg {
  text-align: center;
  background-color: var(--light);
  padding-bottom: 100px;
}
.error-pg img {
  height: 70vh;
  width: 70%;
  object-fit: cover;
  padding-top: 5%;
  background-color: var(--light);
}

.error-pg p, .error-pg a{
  background-color: var(--light);
}

/* Galleries __________________________________________________ */
.work-title{
  color: var(--light);
  display: flex;
  justify-content: right;
  padding-right: 15%;
  font-family: var(--main-font);
  font-size: 3em;
  line-height: 0px;
}
.art-content{
  padding: 0px 5%;
  text-align: center;
}
Image.PreviewGroup, .artwork{
  width: 265px !important;
  height: 265px !important;
  object-fit: cover !important;
}
.work-img-container{
  text-align: right;
  text-align: center;
  padding-right: 15%;

  
}
.come-back-later{
  height: 63vh;
  width: 100vw;
  object-fit: cover;
}

/* Footer __________________________________________________ */

.footer-container{
  text-align: center;
  color: var(--light);
  font-family: var(--main-font);
  padding: 10%;
}
.ft-contact{
  display: flex;
  flex-wrap: space-between;
  justify-content: center;
  align-items: center;
}
.ft-contact-item{
  margin: 0px 20px;
}
.ft-contact-item > p, .ft-contact-item > h3 {
  line-height: 0px;
}

/* for safety */ 
@media print {
  html,
  body {
     display: none;
  }
}

/* ____________ */

body {
  animation: fadeInAnimation ease 2s;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}