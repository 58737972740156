.home-main-container{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)), url('../../Media/Covers/different-brushstrokes-of-golden-paint-drawn-on-gr-2023-11-27-05-13-58-utc.jpeg')
}
#home-container{
    display: flex;  
    justify-content: center;
    margin-top: 20vh;
    padding-bottom: 50vh;
}
#home-text{
    text-decoration: none;
    font-family: var(--third-font);
    color: var(--light);
    font-size: 3em;
    text-align: center;
    transform: scale(1, 1.25);
}
#home-text :hover{
    border-bottom: var(--light) solid 1px;
}

.home-text-hover{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)), url('../../Media/Covers/main_cover.png');
}